import React from 'react';
import { css } from 'styled-components';
import { TitleWithLineH2 } from '../TitleWithLine';
import { ReviewCard } from '../Card/index';
import { CallButton } from '../Button';
import { FlexColumn, CenterContent, Section } from '../StyledComponents';
import { media } from '../../utils/media';
import { space, mediaQueries } from '../../utils/tokens'

export const ReviewsSection = ({ reviews }) => (
  <Section
    css={css`
      padding-top: ${space[5]}px;
      padding-bottom: ${space[5]}px;

      ${mediaQueries.lg} {
        min-height: 100vh;
        background-image: linear-gradient(20deg, #001839 55%, #fff 50%);
        padding: ${space[6]}px 0;
      }

      ${mediaQueries.xxl} {
        height: auto;
        min-height: unset;
      }
    `}
  >
    <div>
      <FlexColumn>
        <TitleWithLineH2
          css={css`
            padding: 0 2rem;
            ${media.desktop`
              color: ${props => props.theme.colors.base};
              padding: 0;
            `}
          `}
        >
          What our clients say
        </TitleWithLineH2>
        <div
          css={css`
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
          `}
        >
          <div
            css={css`
      display: inline-flex;
      align-items: flex-start;
      padding: 1rem;

      > * {
        min-width: 85vw;
      }

      ${media.desktop`
        display: inline-flex;
        align-items: unset;
        padding: 0 25vw;
        > * {
          min-width: 50vw;
        }
      `}

      ${media.desktop`
        display: inline-flex;
        align-items: unset;
        padding: 0 25vw;
        > * {
          min-width: 50vw;
        }
      `}

      ${media.ultrahd`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 1880px;
        margin: auto;
        padding: 0 2rem;

        > * {
          min-width: unset;
        }
      `}
    `}
          >
            {reviews.map((review, index) => {
              return (
                <ReviewCard
                  author={review.reviewer}
                  message={review.review.internal.content}
                  key={index}
                />
              )
            })}
          </div>
        </div>
      </FlexColumn>
      <CenterContent
        css={css`
          padding: 2rem 0;
        `}
      >
        <CallButton
          text="CALL US: 1 (929) 213-9604"
          css={css`
            margin: 0 auto;
          `}
        />
      </CenterContent>
    </div>
  </Section>
)
