import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import Container from '../Container'
import { EmailButton, CallButton } from '../Button'
import {
  fontSizes,
  colors,
  space,
  mediaQueries,
  breakpoints,
} from '../../utils/tokens'

const TheProcessSection = (props) => {
  const { cards } = props

  return (
    <section
      css={css({
        backgroundColor: `${colors.base}`,
        padding: `${space[5]}px ${space[4]}px`,
        color: `${colors.white}`,
        [mediaQueries.lg]: {
          padding: `${space[6]}px ${space[5]}px`,
          clipPath: `polygon(25% 0%, 100% 0, 100% 85%, 5% 100%, 0 45%, 0 0%)`,
        },
      })}
    >
      <Container>
        <h3
          css={css({
            textTransform: `uppercase`,
            fontWeight: `bold`,
            fontSize: fontSizes[2],
            color: `${colors.white}`,
            marginBottom: `${space[3]}px`,
          })}
        >
          The Process
        </h3>
        <p
          css={css({
            fontSize: fontSizes[2],
            opacity: 0.75,
            marginBottom: `${space[5]}px`,
            maxWidth: breakpoints.sm,
          })}
        >
          This is our normal workflow but if you need it done in 2 or 3 days,
          we can do it. (as long as it’s reasonable)
        </p>
        <div
          css={css({
            marginBottom: `${space[6]}px`,
            [mediaQueries.lg]: {
              display: `grid`,
              gridTemplateColumns: `1fr 1fr 1fr 1fr`,
              gridGap: `${space[4]}px`,
            },
          })}
        >
          {cards &&
            cards.map((card, id) => {
              return (
                <div
                  key={id}
                  css={css({
                    marginBottom: `${space[5]}px`,
                    [mediaQueries.lg]: {
                      marginBottom: `0`,
                    },
                  })}
                >
                  {card.media && (
                    <img
                      src={card.media.file.url}
                      alt={card.media.title}
                      css={css({
                        maxHeight: `150px`,
                        minHeight: `150px`,
                        marginBottom: `${space[3]}px`,
                        width: `50%`,
                      })}
                    />
                  )}
                  <h4
                    css={css({
                      color: `${colors.white}`,
                      fontSize: fontSizes[4],
                    })}
                  >
                    {card.title}
                  </h4>
                  <hr
                    css={css({
                      marginTop: `${space[3]}px`,
                      backgroundColor: `${colors.primaryBrand}`,
                      height: `${space[1]}px`,
                      width: `50%`,
                    })}
                  />
                  {card.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.description.childMarkdownRemark.html,
                      }}
                      css={css({
                        opacity: 0.75,
                        p: {
                          marginBottom: `${space[3]}px`,
                        },
                      })}
                    />
                  )}
                </div>
              )
            })}
        </div>
        <div>
          <h5
            css={css({
              color: colors.white,
              fontWeight: `normal`,
              fontSize: fontSizes[2],
              marginBottom: `${space[3]}px`,
            })}
          >
            Need it rushed? Give us a call or email and we'll respond right
            away.
          </h5>
          <div
            css={css({
              [mediaQueries.lg]: {
                display: `grid`,
                gridTemplateColumns: `1fr 1fr`,
                gridGap: `${space[3]}px`,
                width: `50%`,
              },
            })}
          >
            <EmailButton
              css={css({
                width: `100%`,
                marginBottom: `${space[2]}px`,
              })}
            />
            <CallButton
              white
              css={css({
                width: `100%`,
              })}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default TheProcessSection

TheProcessSection.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
}