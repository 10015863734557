import React from 'react'
import { css } from 'styled-components'
import Img from 'gatsby-image'

import {
  space,
  mediaQueries,
  breakpoints,
  fontSizes
} from '../../utils/tokens'

const TwoColumnContentLeft = props => {
  const { section } = props
  return (
    <section
      css={css({
        paddingLeft: `${space[4]}px`,
        paddingRight: `${space[4]}px`,
        paddingTop: `${space[5]}px`,
        paddingBottom: `${space[5]}px`,
        [mediaQueries.lg]: {
          maxWidth: breakpoints.xxl,
          margin: `auto`,
          padding: `${space[5]}px ${space[6]}px`,
        },
      })}
    >
      <div
        css={css({
          maxWidth: `${breakpoints.xl}`,
          margin: `auto`,
        })}
      >
        <div
          css={css({
            display: `grid`,
            [mediaQueries.lg]: {
              gridTemplateColumns: `1fr 1fr`,
              gridGap: `${space[5]}px`,
            }
          })}
        >
          <div>
            <h2
              css={css({
                fontWeight: `bold`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[5],
                },
              })}
            >
              {section.title}
            </h2>
            <div
              css={css({
                lineHeight: `1.8`,
              })}
              dangerouslySetInnerHTML={{
                __html: section.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <Img
            fluid={section.image.fluid}
            alt={section.image.title}
          />
        </div>
      </div>
    </section>
  )
}

export default TwoColumnContentLeft