import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import Img from 'gatsby-image'

import { media } from '../../utils/media'
import { space, mediaQueries, breakpoints } from '../../utils/tokens'

import Link from '../Link'
import Button from '../Button'
import { BlogCard, UseCaseCard, StepCard } from '../Card'
import Container from '../Container'
import Accordion from '../Accordion'
import Masonry, { MasonryBrick } from '../Masonry'
import { Background } from '../StyledComponents'
import { TitleWithLineH3 } from '../TitleWithLine'
import { graphql } from 'gatsby'

const Section = props => {
  return (
    <section>
      <Container>
        <TitleWithLineH3>{props.title}</TitleWithLineH3>
        {props.children}
      </Container>
    </section>
  )
}

export default Section

Section.propTypes = {
  title: PropTypes.string.isRequired,
}

export class RelatedBlogPostSection extends Component {
  constructor(props) {
    super(props)
    this.state = { key: 0 }
  }

  componentDidMount() {
    this.setState({ key: 1 })
  }

  render() {
    const { blogs } = this.props
    return (
      <section>
        <Background
          gray
          css={css({
            [mediaQueries.xxl]: {
              padding: `${space[5]}px 0`,
            }
          })}
        >
          <Container>
            <TitleWithLineH3>Related blog posts</TitleWithLineH3>
          </Container>
          <div
            css={css({
              overflowX: `scroll`,
              padding: `${space[3]}px ${space[3]}px`,
              [mediaQueries.md]: {
                overflowX: `unset`,
                padding: 0,
              }
            })}
          >
            <div
              css={css`
                display: inline-flex;

                > * {
                  padding: ${space[2]}px ${space[2]}px;
                  width: 90vw;
                }

                ${mediaQueries.md} {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  width: 100%;
                  padding: 0 ${space[3]}px;
                  margin: auto;

                  > * {
                    width: 100%;
                    flex: 0 0 50%;
                    padding: ${space[3]}px;
                  }
                }

                ${mediaQueries.lg} {
                  padding: 0;
                  > * {
                    flex: 0 0 33.33%;
                  }
                }

                ${media.ultrahd`
                  max-width: 1880px;
                  margin: auto;
                `}
              `}
            >
              {blogs.map((blog, index) => {
                return (
                  <div key={index}>
                    <Link to={blog.node.fields.path}>
                      <BlogCard
                        key={index}
                        image={blog.node.coverPhoto}
                        tags={blog.node.tags}
                        excerpt={blog.node.article && blog.node.article.childMarkdownRemark.excerpt}
                        title={blog.node.title}
                      />
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: `16px`,
            }}
          >
            <Container size="small">
              <Link to="/blog/">
                <Button text="Read more" primary />
              </Link>
            </Container>
          </div>
        </Background>
      </section>
    )
  }
}

RelatedBlogPostSection.propTypes = {
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.object.isRequired,
    })
  ).isRequired,
}

export const FAQSection = ({ faqs }) => (
  <section
    css={css({
      padding: `${space[5]}px ${space[4]}px`,
      [mediaQueries.lg]: {
        padding: `${space[5]}px ${space[5]}px`,
      }
    })}
  >
    <Background>
      <Container>
        <TitleWithLineH3>F.A.Q</TitleWithLineH3>
        {
          faqs && <Accordion items={faqs} />
        }
      </Container>
    </Background>
  </section>
)

FAQSection.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  })).isRequired,
}

export const HowItWorksSection = ({ steps }) => {
  return (
    <section css={css`
      padding: ${space[4]}px ${space[4]}px;

      ${mediaQueries.lg} {
        padding: ${space[5]}px 0;
      }
    `}>
      <Background>
        <h3 css={css`
          font-weight: bold;
          font-size: 2rem;
          margin-top: 2rem;
          margin-bottom: 1rem;
          text-align: center;

          ${media.desktop`
            font-size: 2.5rem;
            margin-bottom: 4rem;
            margin-top: 3rem;
          `}
        `}>How it works</h3>
          {
            steps &&
            <div css={css`
              ${mediaQueries.md} {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: ${breakpoints.xl};
                margin: auto;
              }
            `}>
              {
                steps.map((step, index) => {
                  return (
                    <div
                      key={step.id}
                      css={css({
                        [mediaQueries.md]: {
                          width: `calc(99.9%/3 - ${space[4]}px)`,
                          margin: `0 ${space[3]}px`
                        },
                        [mediaQueries.lg]: {
                          width: `calc(99.9%/3 - ${space[6]}px)`,
                          margin: `0 ${space[5]}px`,
                        },
                      })}
                    >
                      <StepCard
                        number={index + 1}
                        title={step.title}
                        icon={step.icon}
                        description={step.instructions}
                      />
                    </div>
                  )
                })
              }
            </div>
          }
      </Background>
    </section>
  )
}

// To do export proptypes for howitworkscard
HowItWorksSection.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  )
}


export const UseCaseSection = ({ useCases }) => {
  return (
    <section>
      <Background
        dark
        css={css({
          padding: `${space[5]}px ${space[4]}px`,
          [mediaQueries.lg]: {
            padding: `${space[6]}px ${space[5]}px`,
          }
        })}
      >
        <Container>
          <TitleWithLineH3 light>Use Cases</TitleWithLineH3>
          <ul>
            {useCases &&
              useCases.map((useCase, index) => {
                const isOdd = index % 2 !== 0

                return (
                  <li key={index} style={{ marginBottom: `2rem` }}>
                    <UseCaseCard
                      name={useCase.title}
                      description={useCase.description}
                      image={useCase.coverPhoto}
                      reversed={isOdd}
                      gallery={useCase.gallery}
                    />
                  </li>
                )
              })
            }
          </ul>
        </Container>
      </Background>
    </section>
  )
}

UseCaseSection.propTypes = {
  useCases: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.object,
      coverPhoto: PropTypes.object,
      reversed: PropTypes.bool,
      gallery: PropTypes.array,
    })
  )
}

export { default as MaterialsSection } from './MaterialsSection'
export { default as RecentWorkSection } from './RecentWorkSection'
export { default as LocationsSection } from './LocationsSection'
export { default as ApplicationSection } from './ApplicationSection'
export { default as PricingSection } from './PricingSection'
export { default as FeaturesSection } from './FeaturesSection'
export { default as TwoColumnContentLeft } from './TwoColumnContentLeft'
export { default as WhyUsSection } from './WhyUsSection'
export { default as TypesOfFinishingSection } from './TypesOfFinishingSection'
export { default as TheProcessSection } from './TheProcessSection'
export { AlternatingRowsSection } from './AlternatingRowsSection'
export { default as MaterialsListSection } from './MaterialsListSection'
export { default as TestimonalsSection } from './TestimonialsSection'
export { ReviewsSection } from './ReviewsSection'
export { default as FeaturedWorkSection } from './FeaturedWorkSection'
export { default as GoogleMapEmbedSection } from './GoogleMapEmbedSection'
export { ContentWithPhotoSection } from './ContentWithPhotoSection'
export { FeatureListSection } from './FeatureListSection'
export { GridSection } from './GridSection'
export { CardsListSection } from './CardsListSection'

export const pageSectionFragment = graphql`
  fragment PageSectionFragment on ContentfulPageSection {
    id
    title
    subtitle
    type
    body {
      childMarkdownRemark {
        html
      }
      internal {
        content
      }
    }
    properties {
      key
      value
    }
    richTextBody {
      raw
      references {
        ...RichTextArticleFragment
      }
    }
    gallery {
      ...RecentWorkImages
    }
    customBlock {
      name
      properties {
        key
        value
      }
      assets {
        title
        fixed(height: 500, quality: 90) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      cards {
        title
        media {
          title
          file {
            contentType
            url
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    image {
      title
      fluid(maxWidth: 3000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    cards {
      ... on ContentfulMaterial {
        name
        coverPhoto {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulCard {
        id
        title
        subtitle
        description {
          childMarkdownRemark {
            html
          }
        }
        media {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
            details {
              image {
                width
                height
              }
            }
          }
        }
        buttons {
          ...Buttons
        }
        externalLink
        internalLink {
          __typename
          ... on ContentfulPage {
            fields {
              path
            }
          }
          ... on ContentfulService {
            fields {
              path
            }
          }
        }
      }
    }
  }
`