import React from 'react'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'
import { css } from 'styled-components'

import { mediaQueries, space, fontSizes } from '../../utils/tokens'
import Container from '../Container'
import Button from '../Button'

const FeaturedWorkSection = ({
  featuredWork,
  seeMoreLink,
}) => {
  
  return (
    <section>
      <Container>
        <div
          css={css({
            padding: `${space[4]}px`,
            display: `flex`,
            flexDirection: `column`,
            [mediaQueries.xxl]: {
              padding: `${space[5]}px 0 ${space[6]}px`,
            },
          })}
        >
          <h3
            css={css({
              fontSize: fontSizes[4],
              fontWeight: `bold`,
              marginBottom: `${space[3]}px`,
              [mediaQueries.xxl]: {
                marginBottom: `${space[4]}px`,
              },
            })}
          >
            Featured Client Work
          </h3>
          <div
            css={css({
              display: `grid`,
              gridGap: `${space[3]}px`,
              [mediaQueries.xxl]: {
                gridTemplateColumns: `repeat(12, 1fr)`,
                '> :first-child': {
                  gridColumn: `span 5`,
                },
                '> :nth-child(2)': {
                  gridColumn: `span 7`,
                },
                '> :nth-child(3)': {
                  gridColumn: `span 6`,
                },
                '> div': {
                  gridColumn: `span 3`,
                },
              },
            })}
          >
            {featuredWork &&
              featuredWork.map(work => {
                return <Img key={work.id} fluid={work.fluid} alt={work.title} />
              })}
          </div>
          <div
            css={css({
              alignSelf: `center`,
            })}
          >
            {seeMoreLink && (
              <Button
                css={css({ marginTop: `${space[5]}px` })}
                onClick={() => navigate(seeMoreLink)}
              >
                See more
              </Button>
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default FeaturedWorkSection