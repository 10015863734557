import React from 'react'
import { css } from 'styled-components'

import { space, mediaQueries, fontSizes } from '../../utils/tokens'
import Container from '../Container'

const GoogleMapEmbedSection = ({ section }) => {
  return (
    <section>
      <Container>
        <h3
          css={css({
            fontSize: fontSizes[4],
            fontWeight: `bold`,
            marginBottom: `${space[4]}px`,
            padding: `0 ${space[3]}px`,
          })}
        >
          {section.title}
        </h3>
      </Container>
      <div
        css={css({
          marginBottom: `${space[4]}px`,
          iframe: {
            minHeight: `60vh`,
            height: `100%`,
            width: `100%`,
          },
          [mediaQueries.lg]: {
            marginBottom: 0,
          },
        })}
        dangerouslySetInnerHTML={{
          __html: section.body && section.body.childMarkdownRemark.html,
        }}
      />
    </section>
  )
}

export default GoogleMapEmbedSection