import styled from 'styled-components'

const Masonry = styled.div`
  column-count: 3;
`
export const MasonryBrick = styled.figure`
  background-color: #eee;
  display: inline-block;
  margin: 0 0 1rem;
  width: 100%;
`

export default Masonry