import React, { useState } from 'react'
import Proptypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'

import { media } from '../../utils/media'
import { mediaQueries, space, breakpoints, fontSizes } from '../../utils/tokens'

import Button from '../Button'

const SectionTitle = styled.h3`
  font-size: ${fontSizes[4]};
  font-weight: bold;
  color: ${props => props.theme.colors.base};
  margin-bottom: ${space[3]}px;
  max-width: ${breakpoints.md};

  ${media.desktop`
    font-size: ${fontSizes[5]};
  `}
`

const SectionDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
`

const PrevSliderButton = (props) => {
  const { onClick } = props
  return (
    <Button
      onClick={onClick}
      css={css`
        font-size: 1.5rem;
        min-width: 0;
        width: 64px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 16px);
        right: 50%;
        transform: translateX(-100%);
        padding: 0;

        ${mediaQueries.lg} {
          top: 0;
          bottom: unset;
          left: ${space[5]}px;
          transform: none;
        }

        ${mediaQueries.xxl} {
          left: 0;
        }
      `}
    >
      <FaLongArrowAltLeft />
    </Button>
  )
}

const NextSliderButton = (props) => {
  const { onClick } = props
  return (
    <Button
      onClick={onClick}
      css={css`
        font-size: 1.5rem;
        min-width: 0;
        width: 64px;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: calc(50% - 16px);
        padding: 0;

        ${mediaQueries.lg} {
          top: 0;
          bottom: unset;
          left: calc(${space[5]}px + ${space[3]}px);
          transform: translateX(100%);
        }

        ${mediaQueries.xxl} {
          left: ${space[3]}px;
        }
      `}
    >
      <FaLongArrowAltRight />
    </Button>
  )
}

const GallerySlider = (props) => {
  const { images } = props
  const settings = props.settings || {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    dotsClass: "slick-dots slick-thumb",
    speed: 500,
    accessible: true,
    dots: false,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: <PrevSliderButton />,
    nextArrow: <NextSliderButton />,
    responsive: [{
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        adaptiveHeight: true,
        speed: 500,
      }
    },]
  }

  const [isLightboxOpen, setLightBoxOpen] = useState(false)
  const [currentImageIndex, setCurrentImage] = useState(0)

  const toggleLightBox = (index) => {
    setCurrentImage(index)
    setLightBoxOpen(!isLightboxOpen)
  }

  return (
    <div>
      <Slider
        {...settings}
        css={css`
          &&& {
            position: relative;
            padding-bottom: ${space[5]}px;
            ${mediaQueries.lg} {
              padding-bottom: 0;
              padding-top: ${space[5]}px;
              max-width: ${breakpoints.xl};
              margin: auto;
            }
          }
        `}
      >
        {images.map((image, index) => {
          return (
            <div
              key={`${image.id}-${index}`}
              css={css`
                padding: ${space[3]}px;
                width: 100%;

                ${mediaQueries.lg} {
                  padding: ${space[4]}px;
                }
              `}
            >
              <div
                onClick={() => toggleLightBox(index)}
                css={css`
                  cursor: zoom-in;
                `}
              >
                <Img fixed={image.fixed} alt={image.title} />
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

const RecentWorkSection = (props) => {
  const { gallery, className, serviceName, description, title } = props

  if(!gallery?.images) {
    return null
  }

  const reverseArray = arr => {
    let newArray = []
    for (let i = arr.length - 1; i >= 0; i--) {
      newArray.push(arr[i])
    }

    return newArray
  }

  const reversedImages = reverseArray(gallery.images)
  const limitedImages = reversedImages.slice(0,8)


  return (
    <section
      id="recent-work"
      className={className}
      css={css`
        padding: ${space[5]}px 0;
        background-color: ${props => props.theme.colors.lightGray};

        ${mediaQueries.lg} {
          padding: ${space[6]}px 0;
        }
      `}
    >
      <div
        css={css`
          max-width: ${breakpoints.xl};
          margin: auto;
          padding: 0 ${space[4]}px;

          ${mediaQueries.lg} {
            padding: 0 ${space[5]}px;
          }

          ${mediaQueries.xxl} {
            padding: 0;
          }
        `}
      >
        <SectionTitle>{title || `Recent work`}</SectionTitle>
        {description ? (
          <SectionDescription>{description}</SectionDescription>
        ) : (
          <SectionDescription>
            These are some of the {serviceName && serviceName.toLowerCase()}{' '}
            projects that we helped our clients make.
          </SectionDescription>
        )}
      </div>
      <GallerySlider images={limitedImages} />
    </section>
  )
}

export default RecentWorkSection

export const recentWorkFragment = graphql`
  fragment RecentWorkImages on ContentfulAsset {
    title
    id
    file {
      details {
        image {
          width
          height
        }
      }
    }
    fluid(quality: 100, maxHeight: 900) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    thumbnail: fixed(width: 150) {
      ...GatsbyContentfulFixed_withWebp_noBase64
    }
    fixed(quality: 100, height: 300) {
      ...GatsbyContentfulFixed_withWebp_noBase64
    }
    fullscreen: fluid(quality: 100) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
`

RecentWorkSection.propTypes = {
  gallery: Proptypes.shape({
    images: Proptypes.array.isRequired,
  })
}