import React, { useState } from 'react'
import posed from 'react-pose'
import styled, { css } from 'styled-components'
import { colors, space } from '../utils/tokens'

const VerticalBar = posed.div({
  open: {
    rotate: 0,
    transition: {
      duration: 300,
    }
  },
  closed: {
    rotate: "90deg",
    transition: {
      duration: 300,
    }
  },
})

const SlidingContent = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
})

const Card = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.dropShadow};
`

const TitleGroup = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${props => props.theme.colors.base};
  padding: 1.2rem 2rem;
  border-bottom: 2px solid ${props => props.theme.colors.lightGray};
`

const Title = styled.h5`
  font-size: 1.2rem;
  margin-bottom: 0;
  width: 100%;
`
const Content = styled.div`
  font-size: 1rem;
  padding: 1rem 2rem;
  line-height: 1.8;
  background-color: ${props => props.theme.colors.lightGray};
  border-bottom: 2px solid ${props => props.theme.colors.lightGray};

  > * {
      max-width: 800px;
  }
`

const Accordion = (props) => {
  const [open, setOpen] = useState(false)


  return (
    <Card>
      {props.items.map((item, i) => {
        return (
          <div key={item.id}>
            <TitleGroup onClick={() => setOpen(open === i ? 'false' : i)}>
              <Title>{item.question}</Title>
              <div
                css={css`
                  margin-right: 4px;
                `}
              >
                <div
                  css={css({
                    width: `16px`,
                    height: `3px`,
                    position: `absolute`,
                    backgroundColor: colors.base,
                  })}
                />
                <VerticalBar
                  pose={open === i ? 'open' : 'closed'}
                  css={css({
                    width: `16px`,
                    height: `3px`,
                    position: `absolute`,
                    backgroundColor: colors.base,
                  })}
                />
              </div>
            </TitleGroup>
            <SlidingContent
              pose={open === i ? 'open' : 'closed'}
              css={css`
                overflow: hidden;
              `}
            >
              <Content
                dangerouslySetInnerHTML={{
                  __html: item.answer.childMarkdownRemark.html,
                }}
                css={css({
                  p: {
                    marginBottom: `${space[3]}px`,
                  }
                })}
              />
            </SlidingContent>
          </div>
        )
      })}
    </Card>
  )
}

export default Accordion