import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'
import Gallery from 'react-photo-gallery'
import { css } from 'styled-components'

import Container from '../Container'
import {
  fontSizes,
  space,
  mediaQueries,
  letterSpacings,
  breakpoints,
} from '../../utils/tokens'

const TypesOfFinishing = (props) => {
  const { sections } = props

  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `${space[6]}px ${space[5]}px`,
        },
      })}
    >
      <Container>
        <h2
          css={css({
            textTransform: `uppercase`,
            fontWeight: `bold`,
            fontSize: fontSizes[2],
            marginBottom: `${space[3]}px`,
          })}
        >
          Types of finishing
        </h2>
        {sections &&
          sections.map((section, id) => {
            const isOdd = id % 2 === 0
            return (
              <section
                key={id}
                css={css({
                  padding: `${space[4]}px 0`,
                })}
              >
                <div
                  css={css({
                    [mediaQueries.lg]: {
                      display: `flex`,
                      flexDirection: `column`,
                    },
                  })}
                >
                  <div
                    css={css({
                      [mediaQueries.lg]: {
                        paddingBottom: `${space[4]}px`,
                      },
                    })}
                  >
                    <h4
                      css={css({
                        fontWeight: `bold`,
                        fontSize: fontSizes[4],
                        marginBottom: `${space[3]}px`,
                      })}
                    >
                      {section.title}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.body.childMarkdownRemark.html,
                      }}
                      css={css({
                        marginBottom: `${space[4]}px`,
                        letterSpacing: letterSpacings.normal,
                        maxWidth: breakpoints.sm,
                        p: {
                          marginBottom: `${space[3]}px`,
                        },
                      })}
                    />
                  </div>
                  {section.cards && (
                    <div
                      css={css({
                        margin: `0 -8px`,
                      })}
                    >
                      <FinishingGallery cards={section.cards} />
                    </div>
                  )}
                </div>
              </section>
            )
          })
        }
      </Container>
    </section>
  )
}

const FinishingGallery = ({ cards }) => {
  let galleryPhotos = []

  if(cards?.length > 0) {
    for(var i = 0; i < cards.length; i++) {
      const card = cards[i]
      const image = card.media
      
      galleryPhotos[i] = {
        src: image.fluid?.src,
        width: image?.file?.details?.image?.width,
        height: image?.file?.details?.image?.height,
        fluid: image?.fluid,
        key: image.id,
        title: image.title,
        caption: card.title,
      }
    }
  } 
  return (
    <Gallery
      photos={galleryPhotos}
      targetRowHeight={600}
      margin={8}
      renderImage={({ index, left, top, key, photo, margin }) => {
        return (
          <Photo
            key={key}
            index={index}
            photo={photo}
            left={left}
            top={top}
            margin={margin}
          />
        )
      }}
    />
  )
}

const Photo = ({ photo, margin, index, top, left }) => {
  return (
    <figure
      css={{
        margin: `${margin}px`,
        position: `relative`,
        overflow: `hidden`,
        width: `100%`,
        height: `auto`,
        [mediaQueries.md]: {
          height: photo.height,
          width: photo.width,
        },
      }}
    >
      <GatsbyImage fluid={photo.fluid} />
      <figcaption
        css={css({
          position: `absolute`,
          fontWeight: `bold`,
          display: `flex`,
          alignItems: `center`,
          bottom: `${space[3]}px`,
          left: `${space[3]}px`,
          zIndex: 1,
          backgroundColor: `white`,
          borderRadius: `2px`,
          padding: `${space[1]}px ${space[2]}px`,
        })}
      >
        {photo.caption}
      </figcaption>
    </figure>
  )
}

export default TypesOfFinishing

TypesOfFinishing.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
}