import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import posed from 'react-pose'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import { groupBy } from 'lodash'

import { MaterialCard } from '../Card'
import { FullCardInformation } from '../Card/MaterialCard'
import { Background } from '../StyledComponents'

import { media } from '../../utils/media'
import { fontSizes, mediaQueries, space, breakpoints } from '../../utils/tokens'
import theme from '../../styles/theme'

const ScrollMarker = styled.div`
  position: absolute;
  top: 0px;
  right: -1rem;
  height: 100%;
  width: 60px;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255, 1) 100%);
`

const SectionTitle = styled.h3`
  font-size: ${fontSizes[4]};
  color: ${props => props.theme.colors.base};
  text-align: left;
  padding: 0 ${space[4]}px;
  font-weight: bold;
  margin-bottom: ${space[4]}px;

  ${mediaQueries.lg} {
    padding: 0 ${space[5]}px;
    text-align: right;
    font-size: ${fontSizes[5]};
    max-width: ${breakpoints.xxl};
    margin-left: auto;
    margin-right: auto;
  }
`

const SlidingContent = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' },
})

export const MaterialsSection = (props) => {
  const [selectedCardId, setSelectedCard] = useState(null)
  const [selectedRowId, setSelectedRowId] = useState(null)

  const groupedMaterials = groupBy(props.materials, (material) => {
    return material.category
  })

  
  const handleCardClick = (rowId, cardId) => {
    // if we're not pressing the same card
    // set the new card
    if (selectedCardId !== cardId) {
      setSelectedCard(cardId)
      setSelectedRowId(rowId)
    } else {
      // if we're pressing the same card again
      // we reset everything
      setSelectedCard(null)
      setSelectedRowId(null)
    }
  }

  return (
    <section>
      <Background
        css={css({
          padding: `${space[5]}px 0`
        })}
      >
        <SectionTitle>Materials</SectionTitle>
        <div
          css={css`
            ${media.desktop`
              padding: 0;
              max-width: ${props => props.theme.sizes.maxWidth};
              margin-left: auto;
            `}

            ${media.hd`
              max-width: unset;
              margin-left: auto;
            `}

            ${media.ultrahd`
              max-width: 1880px;
              padding: 4rem;
              margin: auto;
            `}
          `}
        >
          {Object.keys(groupedMaterials).map((category, rowIndex) => {
            const materials = groupedMaterials[category]
            return (
              <div
                key={rowIndex}
                css={css`
                  margin-bottom: ${space[4]}px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  ${mediaQueries.lg} {
                    margin-bottom: ${space[5]}px;
                  }
                `}
              >
                <h4
                  css={css`
                    font-size: 1.5rem;
                    text-transform: capitalize;
                    color: ${props => props.theme.colors.base}c4;
                    margin-bottom: 1rem;
                    font-weight: 500;
                    margin-left: 1rem;

                    ${media.hd`
                      min-width: 1440px;
                      padding-left: 3rem;
                      margin-left: 0;
                    `}
                  `}
                >
                  {category !== 'null' ? category : 'other'}
                </h4>

                <Tabs
                  defaultIndex={0}
                  onChange={index =>
                    handleCardClick(rowIndex, materials[index].id)
                  }
                  css={css`
                    position: relative;
                    overflow: hidden;
                  `}
                >
                  <TabList
                    key={rowIndex}
                    css={css`
                      display: inline-flex;
                      width: 100%;
                      max-width: ${props =>
                        props.theme.sizes.maxWidth};
                      overflow-x: scroll;
                      -webkit-overflow-scrolling: touch;

                      &::before {
                        content: '';
                        padding-left: 1rem;

                        ${media.desktop`
                          padding: 0;
                        `}

                        ${media.ultrahd`
                          display: none;
                        `}
                      }

                      &::after {
                        content: '';
                        padding-right: 4rem;

                        ${media.desktop`
                          padding-right: 2rem;
                        `}

                        ${media.ultrahd`
                          display: none;
                        `}
                      }

                      ${media.hd`
                        min-width: 1440px;
                        max-width: unset;
                        padding-left: 3rem;
                      `}

                      ${media.ultrahd`
                        max-width: 1880px;
                        display: grid;
                        grid-template-columns: repeat(8, 1fr);
                      `}
                    `}
                  >
                    {materials &&
                      materials.map(material => {
                        return (
                          <Tab
                            key={material.id}
                            css={css`
                              border: none;
                              background-color: transparent;
                              flex: 0 0 50vw;
                              padding-right: 0.25rem;
                              padding-left: 0.25rem;
                              padding-bottom: 0.5rem;
                              padding-top: 0.5rem;

                              :first-child {
                                padding-left: 0;
                              }

                              :focus {
                                outline: none;
                                > div {
                                  ${material.id === selectedCardId
                                    ? `box-shadow: 0 0 0 4px ${
                                        theme.colors.primaryBrand
                                      };
                                      border-radius: 10px;`
                                    : null}
                                }
                              }

                              ${media.desktop`
                                flex: 0 0 25vw;
                              `}
                            `}
                          >
                            <MaterialCard
                              onClick={() =>
                                handleCardClick(
                                  rowIndex,
                                  material.id
                                )
                              }
                              key={material.id}
                              material={material}
                            />
                          </Tab>
                        )
                      })}
                  </TabList>
                  <TabPanels
                    css={css`
                      overflow: hidden;
                    `}
                  >
                    {materials.map(material => {
                      /** This allows us to hide the tab panel since we
                      set selectedCardId to null initially **/
                      const isDifferentMaterial =
                        material.id !== selectedCardId
                      const isDifferentRow =
                        rowIndex !== selectedRowId

                      return (
                        <TabPanel
                          key={material.id}
                          css={css`
                            :focus {
                              outline: none;
                            }

                            padding: 1rem;

                            ${media.hd`
                              max-width: 1200px;
                              margin: auto;
                              padding: 0;
                            `}
                          `}
                        >
                          <SlidingContent
                            pose={
                              isDifferentMaterial &&
                              isDifferentRow
                                ? 'closed'
                                : 'open'
                            }
                          >
                            <FullCardInformation
                              material={material}
                            />
                          </SlidingContent>
                        </TabPanel>
                      )
                    })}
                  </TabPanels>
                  <ScrollMarker />
                </Tabs>
              </div>
            )
          })}
        </div>
      </Background>
    </section>
  )
}

MaterialsSection.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  )
}

export default MaterialsSection